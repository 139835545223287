@import 'src/styles/variables';
$desktop: 1280px;
$tablet: 768px;
$tabletLarge: 1024px;

// Mobile
.main-container {
  --header-height: $header-height;
  --header-height-lg: $header-height-lg;

  display: flex;
  width: 100%;
  height: 100%;

  & .main-layout {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
    background-color: var(--page-bg);
    & .go-back {
      margin-right: 14px;
    }
  }

  & .page-container {
    padding: 18px;
    height: 100%;
    background-color: var(--page-bg);
    min-height: calc(100vh - var(--header-height));

    @media (min-width: $screen-lg) {
      min-height: calc(100vh - var(--header-height-lg));
    }

    &--msg {
      display: flex;
    }

    &--mobile {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      min-height: 100%;

      &-msg {
        min-height: auto;
      }

      &_event {
        //background-color: var(--white);
      }

      & .mobile-controls__wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 2px;
      background-color: var(--lavender);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: var(--orange-2);
    }
  }

  //TODO доделать под мобилы
  & .page-body {
    padding: 18px;
    height: 100%;
    background-color: var(--snow);

    //height: calc(100vh - 84px);
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 2px;
      background-color: var(--lavender);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: var(--orange-2);
    }
  }
  & .mobile-body {
    padding: 18px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--snow);
  }
}
//Tablet
@media screen and (min-width: $tablet) {
  .main-container {
    & .button--header-menu {
      margin-right: 50px;
    }
    & .page-body {
      //padding: 20px;
      margin-bottom: 0;
    }

    & .page-container {
      padding: 30px 20px;
      //padding: 0;

      &--msg {
        padding-right: 0;
      }
    }

    .msg-layout {
      padding: 30px 0 30px 20px;
    }
  }
}

//Desktop
@media screen and (min-width: $desktop) {
  .main-container {
    & .main-layout {
      width: calc(100% - 256px);
    }
    & .page-body {
      padding: 34px 42px;
    }

    & .page-container {
      padding: 34px 160px 34px 42px;
      position: relative;
      z-index: 1;
    }
  }
}
