$desktop: 1280px;
$tablet: 768px;
$tabletLarge: 1024px;

.sk-grounds {
  &--grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 339px);
    gap: 11px;
  }

  &-team--grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 339px);
    gap: 11px;
  }

  &--element {
    width: 339px;
    height: 200px;
  }

  &-team--element {
    width: 339px;
    height: 166px;
  }
}

//Tablet
@media screen and (min-width: $tablet) {
  .sk-grounds {
    &--grid {
      grid-template-columns: repeat(auto-fill, 354px);
      gap: 20px;
    }

    &-team--grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, 354px);
      gap: 20px;
    }

    &--element {
      width: 354px;
      height: 207px;
    }

    &-team--element {
      width: 354px;
      height: 160px;
    }
  }
}

@media screen and (min-width: $tabletLarge) {
  .sk-grounds {
    &--grid {
      grid-template-columns: repeat(auto-fill, 314px);
      gap: 20px;
    }

    &-team--grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, 462px);
      gap: 20px;
    }

    &--element {
      width: 314px;
      height: 184px;
    }

    &-team--element {
      width: 462px !important;
      height: 145px !important;
    }
  }
}

//Desktop
@media screen and (min-width: $desktop) {
  .sk-grounds {
    &--grid {
      grid-template-columns: repeat(auto-fill, 341px);
      gap: 20px;
    }

    &-team--grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, 462px) !important;
      column-gap: 14px;
      row-gap: 20px;
    }

    &--element {
      width: 341px;
      height: 200px;
    }

    &-team--element {
      width: 462px;
      height: 136px;
    }
  }
}
