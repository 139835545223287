$desktop: 1280px;
$tablet: 768px;

.modal {
  &-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20000;
    &:focus {
      outline: none;
    }
  }

  &-container {
    background: var(--cards-bg);
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    &:focus {
      outline: none;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &:focus {
      outline: none;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    min-height: 58px;
    height: fit-content;
    background-color: var(--header-bg);
    border-radius: 6px 6px 0 0;

    &__title {
      font-weight: 600;
      font-size: var(--title);
      line-height: 16px;

      color: var(--title-color);
    }

    & .button--icon {
      width: 14px;
      height: 14px;

      & svg {
        width: 14px;
        height: 14px;
      }
    }
    & .icon-wrapper {
      color: var(--title-color);
    }
  }

  &-content {
    flex: 1;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &-footer {
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 18px 18px 18px;
  }
}

//Tablet
@media screen and (min-width: $tablet) {
  .modal {
    &-header {
      padding: 20px;
    }
    &-footer {
      margin: 0 20px 40px 20px;
    }
  }
}
