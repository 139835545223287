@mixin line-clamp($line: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin scroll() {
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: #e4e4e7;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: var(--orange-2);
  }
}

@mixin scrollHidden() {
  &::-webkit-scrollbar {
    width: 0;
  }
}
