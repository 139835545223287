.page-profile-mobile {
  display: flex;
  flex-direction: column;

  & .card-photo__wrap {
    align-self: center;

    margin-bottom: 28px;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;

    background-color: #f1f1f3;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;

    .card__photo {
      object-fit: cover;
      width: 180px;
      height: 180px;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;

      &_dummy {
        width: 42px !important;
        height: 48px !important;
        border-radius: 0;
      }

      &-button-wrap {
        position: absolute;

        width: 44px;
        height: 44px;

        display: flex;
        align-items: center;
        justify-content: center;

        top: 72%;
        left: 72%;
      }
    }
  }

  & .input__nickname,
  .select__game-class,
  .select__amplua,
  .select__birthday-view,
  .select__country,
  .select__region,
  .select__city {
    margin-bottom: 24px;
  }

  & .input__about {
    margin-bottom: 20px;
  }

  & .selected-language,
  & .portal-lang {
    margin-bottom: 30px;
  }

  & .portal-lang--open {
    margin-bottom: 110px;
  }

  & .input__birthday {
    margin-bottom: 8px;
  }

  & .bd__label {
    display: block;
    font-size: 14px;
    line-height: 17px;

    color: #77706a;
    margin-bottom: 10px;
  }
}
