@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('fonts/Montserrat-Thin.ttf');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('fonts/Montserrat-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('fonts/Montserrat-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('fonts/Montserrat-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('fonts/Montserrat-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('fonts/Montserrat-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('fonts/Montserrat-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
