@import '../assets/fonts';
@import 'variables';

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

.no-scroll {
  overflow: hidden;
}

.no-scroll-x {
  overflow-x: hidden;
}

#root {
  min-height: 100vh;

  .main-container .main-layout {
    min-height: 100vh;
  }
}

body {
  font-family: Montserrat, sans-serif;
  background: var(--main-bg-color);

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: var(--lavender);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: var(--orange-2);
  }
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  border-radius: 6px;
  background-color: var(--cards-bg);
  box-shadow: none;
  border: 1px solid var(--grey-1);
}
.block {
  border-radius: 6px;
  background-color: var(--block-bg);
  box-shadow: none;
  border: 1px solid var(--grey-1);
}
.text-block {
  display:flex;
  & .rules-filter {
    margin-right:20px;
  }
}
.rules-filter {
  margin-right:20px;
}

.info-text,
.jodit-container {
  max-width: 1193px;
  & a {
    color: var(--a-color);
    text-decoration: underline;
    &:hover {
      color: var(--a-color-hover);
      text-decoration: none;
    }
  }
  & h1 {
    font-style: normal;
    font-weight: bold;
    font-size: var(--title);
    line-height: 16px;
    color: var(--h1);
  }
  & h2 {
    font-style: normal;
    font-weight: bold;
    font-size: var(--subtitle);
    line-height: 14px;
    color: var(--h2);
  }
  font-style: normal;
  font-weight: normal;
  font-size: var(--text);
  line-height: 12px;
  color: var(--container);
  & ul,
  & ol {
    font-weight: 500;
    font-size: var(--text);
    line-height: 12px;
    color: var(--ul-ol);
  }
}
.ReactModal__Overlay {
  z-index: 9999 !important;
}
.block-pointer {
  cursor: pointer;
}
.recaptcha {
  max-width: 100%;
  overflow: hidden;
}
#lightboxBackdrop {
  & figure {
    background-color: var(--menu-bg);
    //padding-top: 8px 8px 0 8px;
  }
}
/* SWITCH TYPE */
.switch-type {
  display: flex;
  flex-wrap: wrap;

  & input[type='checkbox'],
  & input[type='radio'] {
    display: none;
  }

  &_radio_btn {
    margin-top: 10px;
    margin-right: 8px;
    & label {
      cursor: pointer;
      border: var(--switch-type-border);
      box-sizing: border-box;
      border-radius: 6px;
      background: transparent;

      font-weight: 500;
      font-size: var(--text);
      line-height: 12px;

      color: var(--switch-type-color);

      display: flex;
      align-items: center;
      justify-content: center;

      height: 34px;
      width: fit-content;
      padding: 0 10px;
    }
  }

  &_radio_btn input[type='checkbox']:checked + label,
  &_radio_btn input[type='radio']:checked + label {
    background: var(--switch-type-active-bg);
    box-shadow: var(--switch-type-active-shadow);
    border: 0;
    border-radius: 6px;

    font-weight: 700;
    font-size: var(--text);
    line-height: 12px;

    color: var(--switch-type-active-color);
  }
}

.settings-nav {
  display: flex;
  flex-direction: column;
  margin-top: -18px;

  height: 100%;
  width: 100%;

  &__row {
    display: flex;
    align-items: center;
    height: 58px !important;
    border-bottom: 2px solid var(--grey-1);

    &__text {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: var(--settings-nav-link-color);
    }
  }

  & .theme__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 58px !important;

    & .theme-name {
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;

      color: var(--settings-nav-link-color);

      padding-bottom: 0;
    }

    & .toggle {
      justify-content: flex-end;
    }
  }

  &__link {
    text-decoration: none;
  }

  &__link__row {
    & .icon-wrapper {
      color: var(--settings-nav-icon-color);
      margin-right: 9px;

      &:first-child {
        color: var(--orange-2);
      }
    }

    & .icon--chevron {
      margin-left: auto;
      transform: rotate(-90deg);
      margin-right: -10px;
    }
  }
}

.phone-input-container {
  & .special-label {
    color: var(--title-color);
  }

  input {
    height: 35px;
    box-shadow: none;
    background: var(--input-bg) !important;
    border: 1px solid var(--input-border);
    border-radius: 6px;
    padding: 0 15px;
    caret-color: var(--input-caret);
    color: var(--input-text-color) !important;

    &:focus,
    &:-webkit-autofill:focus {
      border: 1px solid var(--input-focus) !important;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: var(--input-text-color);
      border: 1px solid var(--input-bg);
      -webkit-box-shadow: 0 0 0 1000px var(--input-bg) inset !important;
    }
  }
}
.input__container {
  & .input__label {
    color: var(--title-color);
  }

  & .input__wrap {
    padding: 0;
    box-shadow: none;

    & input {
      height: 35px;
      box-shadow: none;
      background: var(--input-bg);
      border: 1px solid var(--input-border);
      border-radius: 6px;
      padding: 0 15px;
      caret-color: var(--input-caret);
      color: var(--block-text-color);

      &:focus,
      &:-webkit-autofill:focus {
        border: 1px solid var(--input-focus) !important;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:active {
        -webkit-text-fill-color: var(--input-text-color);
        border: 1px solid var(--input-bg);
        -webkit-box-shadow: 0 0 0 1000px var(--input-bg) inset !important;
      }
    }
    & textarea {
      box-shadow: none;
      background: var(--input-bg);
      border: 1px solid var(--input-border);
      border-radius: 6px;
      padding: 10px 15px;
      caret-color: var(--input-caret);
      color: var(--block-text-color);
      &:focus {
        border: 1px solid var(--input-focus) !important;
      }
    }
  }
}

.filtersWrap {
  margin-bottom: 42px;

  & .filters {
    display: grid;
    grid-template-columns: repeat(auto-fill, 305px);

    row-gap: 30px;
    justify-content: space-between;

    width: 100%;

    min-height: 95px;
    height: fit-content;

    &__type {
      display: flex;
      flex-direction: column;
      margin-right: 0;
    }

    &_element {
      & .select__wrapper {
        margin-top: 10px;
      }
    }

    &__label {
      font-weight: 500;
      font-size: var(--subtitle);
      line-height: 14px;

      color: var(--title-color);
    }

    &__age {
      display: flex;
      flex-direction: column;
      width: 212px;
      height: 88px;
      //margin-right: 88px;

      & .filters__label {
        margin-bottom: 17px;
      }

      & .input-range {
        margin-bottom: 12px;
      }
    }

    &__country {
      display: flex;
      flex-direction: column;
      width: 305px;
      height: 88px;
      //margin-right: 88px;

      & .filters__label {
        margin-bottom: 17px;
      }

      & .input-range {
        margin-bottom: 12px;
      }
    }

    &__city {
      display: flex;
      flex-direction: column;
      width: 305px;
      height: 88px;
      //margin-right: 90px;
      .Select-menu-outer {
        .Select-option {
          padding-left: 2em;
        }

        .Select-option.is-disabled {
          cursor: default;
          padding-left: 1em;
          font-weight: bold;
        }
      }

      & .filters__label {
        margin-bottom: 17px;
      }

      & .input-range {
        margin-bottom: 12px;
      }
    }

    &__recruitment {
      display: flex;
      flex-direction: row;
      width: fit-content;
      height: fit-content;
      align-self: flex-start;

      & .icon-wrapper {
        align-items: start;
        margin-right: 14px;
        cursor: pointer;
      }

      & .filters__label {
        margin-bottom: 17px;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
.owner-btn {
  height: 35px;
  border: none;

  background: var(--light-button-bg);
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 999px;

  font-weight: 600;
  font-size: var(--subtitle);
  line-height: 14px;

  color: var(--light-button-color);

  &:hover {
    transition: 0.3s;
    background-color: var(--light-button-hover-bg);

    .icon-wrapper {
      z-index: 5;
    }
  }
  &__friends,
  &__message {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 224px;
    background: linear-gradient(85.13deg, #e26e43 -8.05%, #f8ce0e 162.8%);
    box-shadow: 0 2px 10px rgba(235, 147, 48, 0.3);

    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: var(--light-button-color);

    &:disabled {
      background: #e4e4e7;
      box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);

      cursor: not-allowed;
    }
  }

  &__friends {
    margin-bottom: 12px;
  }

  &__was_sent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 224px;

    background: #e4e4e7;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 999px;

    margin-bottom: 12px;
  }

  &__in_friends {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 224px;

    background: var(--light-button-bg);
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 999px;

    margin-bottom: 12px;

    &__wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      & .owner-btn__text {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;

        color: var(--light-button-color);

        margin-right: 10px;
      }

      & .icon--chevronSmall {
        color: #ae9f98;
      }
    }

    & .drop-container {
      top: 54px;
    }
  }
}
.owner-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100% !important;

  &__top {
    position: relative;
    padding: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .owner-photo__wrap {
      margin-bottom: 24px;
      width: 140px;
      height: 140px;

      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--main-bg-color);

      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;

      & img {
        width: 140px;
        height: 140px;
        object-fit: cover;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
      }

      & .profile-avatar {
        border-radius: 50%;
        width: 140px;
        height: 140px;
      }

      & .owner__photo_dummy {
        width: 30px !important;
        height: 34px !important;
        border-radius: 0 !important;
      }
    }
  }

  &__user-info-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: #252321;

    margin-bottom: 24px;

    & h3,
    & .nick-fs {
      font-weight: 600;
      font-size: var(--title);
      line-height: 16px;
      color: var(--block-title-color);

      margin: 0 0 10px 0;

      &_21 {
        font-size: 21px;
      }
      &_19 {
        font-size: 19px;
      }
      &_17 {
        font-size: 17px;
      }
      &_15 {
        font-size: 15px;
      }
    }

    & span {
      font-size: var(--subtitle);
      line-height: 14px;
      color: var(--block-text-color);
    }
  }

  & .playing-role__wrap {
    position: absolute;

    display: flex;
    flex-direction: column;
    left: 86%;

    & .playing-role__elem {
      width: 36px;
      height: 36px;

      color: var(--orange-2);

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #eb9330;
      border-radius: 6px;
    }

    & .playing-role__elem:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  & .qr-code__wrap {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 4%;

    & .qr-code__elem {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
    }
  }
  &__bottom {
    display: flex;
    background: var(--block-bg);
    border-radius: 0 0 6px 6px;
    height: 100%;
  }

  &__user-no-data {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    text-decoration: none;

    color: var(--block-nodata-color);
  }

  & .friends-block-sk-text {
    width: 200px;
    height: 20px;
  }

  & .friends-block-sk-btn {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  & .friends-block-sk-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

@media screen and (max-width: $tabletLargeMax) {
  .filtersWrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9000;
    margin-bottom: 0;

    &__filtersOverlay {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 1;
      border-radius: 0;
    }
    .filters {
      position: absolute;
      height: auto;
      display: block;

      z-index: 2;
      & > div {
        width: 100%;
        margin: 0 0 30px 0;
        &:first-child {
          margin-bottom: 20px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      &__recruitment {
        height: auto;
      }

      &__header {
        &__title {
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 27px;
          color: var(--title-color);
        }
        &__clear {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translate(0%, -50%);

          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;

          color: var(--a-color);

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
@media screen and (min-width: $tablet) and (max-width: $tabletLargeMax) {
  .filtersWrap {
    &__filtersOverlay {
      /*background: transparent;*/
      background: rgba(0, 0, 0, 0.14);
      backdrop-filter: blur(3px);
    }
    .filters {
      top: 84px;
      bottom: 0;
      right: 0;
      width: 340px;
      background: var(--menu-bg);
      box-shadow: -10px 0px 36px rgba(0, 0, 0, 0.08);
      border-radius: 6px 0 0 6px;
      padding: 77px 20px 20px 20px;

      &__header {
        display: block;
        background: var(--filters-gradient);
        border-radius: 6px 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 54px;
        padding: 0 20px;

        &__title {
          font-size: 16px;
          line-height: 54px;
          color: var(--title-color);
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .filtersWrap {
    &__filtersOverlay {
      background: rgba(0, 0, 0, 0.14);
      backdrop-filter: blur(3px);
    }

    .filters {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--menu-bg);
      box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
      border-radius: 6px 6px 0 0;
      padding: 30px 20px;

      &__header {
        position: relative;
        &__SwipeRect {
          position: absolute;
          width: 90px;
          height: 4px;
          left: 50%;
          top: -20px;

          transform: translate(-50%, 0%);

          background: var(--grey-1);
          border-radius: 6px;
        }
      }
    }
  }
}
