.social-block {
  display: flex;
  flex-direction: column;

  grid-row-start: 2;
  grid-row-end: 3;

  grid-column-start: 2;
  grid-column-end: 3;
  &__row {
    display: flex;

    & a {
      & img {
        width: 30px;
        height: 30px;
      }
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
