@import 'root_colors';

.white-theme {
  /* Texts */
  --title-color: #333333;
  --text-color: #333333;
  --links-color: #4c62ff;
  --dark-text-color: #77706a;
  --block-title-color: #252321;
  --block-text-color: #5c5752;
  --block-nodata-color: #4c62ff;
  --block-more-color: #4c62ff;
  --card-title-color: #252321;
  --card-text-color: #5c5752;
  --msg-count-text: #ffffff;

  /* Page|Block|Card Background */
  --cartbg: var(--menu-bg);
  --page-bg: #fafafa;
  --block-bg: #f7f7f8;
  --cards-bg: #ffffff;

  /* Chat */
  --chat-bg: #ffffff;
  --toggle: #f4f4f6;
  --toggle-icon: #c0b4af;
  --separatopr: #f4f4f5;
  --text-time: #9f9993;
  --active: linear-gradient(90deg, #f4f4f6 0%, rgba(244, 244, 246, 0.15) 100%);
  --msg-input-bg: #ffffff;
  --msg-input-shadow: 0 4px 36px rgba(0, 0, 0, 0.08);
  --msg-input-text: #333333;
  --msg-input-place: #b7b2ae;
  --msg-button-bg: #ffffff;
  --msg-button-color: #c0b4af;
  --msg-divider-day-text: #9f9993;
  --msg-divider-day-line: #f1f1f3;
  --msg-block-own: #d9dcf8;
  --msg-text-own: #252321;
  --msg-panel-bg: linear-gradient(85.13deg, #e26e43 -8.05%, #f8ce0e 162.8%);
  --msg-filter-btn-text: #c0b4af;
  --msg-filter-btn-text-active: #252321;
  --msg-filter-btn-bg: #f4f4f6;
  --msg-filter-btn-bg-gradient: linear-gradient(85.13deg, #e26e43 -8.05%, #f8ce0e 162.8%);
  --msg-filter-btn-bg-active: #ffffff;
  --msg-filter-btn-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  --msg-button-delete: #ff0000;
  --msg-message-read: #3399ff;

  /* Icons */
  --cards-icon: #c0b4af;

  --dd-hover: linear-gradient(90deg, #f4f4f6 0%, rgba(244, 244, 246, 0.15) 100%);
  --search-border: 1px solid transparent;

  --button-start: #e26e43;
  --button-end: #f8ce0e;
  --button-hover-start: #d26d3c;
  --button-hover-end: #dc9b23;
  --button-color: #fff;

  /* input */
  --input-bg: #f7f7f8;
  --input-border: #f7f7f8;
  --input-caret: #fa9a2e;
  --input-focus: #fa9a2e;
  --input-text-color: #333333;

  /* header sidebar button */
  --button-header-menu: #c0b4af;
  --main-bg-color: #fafafa;

  /* jodit */
  --h1: #252321;
  --h2: #252321;
  --container: #252321;
  --ul-ol: #252321;
  --a-color: #fa9a2e;
  --a-color-hover: #333;

  /* AUTH */
  --auth-fileld-bg: #fff;
  --auth-fileld-border: #e7e7e9;
  --auth-a-hover: #333333;

  /* MENU */
  --menu-hover: linear-gradient(90deg, rgba(244, 244, 246, 0.15) 0%, #f4f4f6 100%);
  --menu-bg: #ffffff;
  --menu-link: #333333;

  /* HEADER */
  --header-bg: #f1f1f3;
  --header-bottom-bg: #fafafa;
  --header-dummy: #fafafa;
  --header-link-active: #eb9330;
  --header-link: #333333;
  --header-menu-dropdown-bg: #ffffff;
  --header-menu-dropdown-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);

  /* HEADER SEARCH */
  --search-bg: #f7f7f8;
  --search-shadow: 0 4px 36px rgba(0, 0, 0, 0.08);
  --search-placeholder: #9f9993;
  --search-btn: #b6b6b9;

  /* PROFILE MENU */
  --profile-menu-image-bg: #dedee3;
  --profile-menu-avatar-bg: #f1f1f3;
  --profile-menu-dropdown-bg: #fff;
  --profile-menu-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
  --profile-menu-link: #c0b4af;
  --profile-menu-link-active: #eb9330;
  --profile-menu-text-color: #252321;
  --profile-menu-gradient: linear-gradient(90deg, #f4f4f6 0%, rgba(244, 244, 246, 0.15) 100%);

  /* FILTER RANGE */
  --range-track-color: #e4e4e7;
  --range-track-color-active: #f2cea6;
  --range-gradient: linear-gradient(85.13deg, #e26e43 -8.05%, #f8ce0e 162.8%);
  --range-shadow: 0 2px 6px rgba(235, 147, 48, 0.3);
  --range-text: #5c5752;
  --range-input-bg: #e9e9ec;
  --range-input-color: #252321;

  /* FILTER SWITCH TYPE */
  --switch-type-border-color: #e2e2e4;
  --switch-type-selected: #fa9a2e;
  --switch-type-border: 2px solid #cfccc9;
  --switch-type-color: #77706a;
  --switch-type-active-bg: linear-gradient(85.13deg, #e26e43 -8.05%, #f8ce0e 162.8%);
  --switch-type-active-shadow: 0 2px 10px rgba(235, 147, 48, 0.3);
  --switch-type-active-color: #fff;

  /* SELECT */
  --select-container-error: 1px solid red;
  --select-placeholder-color: #b2b2b2;
  --select-value-color: #333333;
  --select-bg: #f2f2f3;
  --select-option-bg: #eb9330;
  --select-option-hover-bg: #eeab6a;
  --select-option-color-active: #fff;

  /* SETTINGS NAV */
  --settings-nav-icon-color: #c0b4af;
  --settings-nav-link-color: #252321;

  /* SETTINGS LINKS */
  --settings-links-item-shadow: 0 4px 36px rgba(0, 0, 0, 0.08);
  --settings-links-item-link-color: #77706a;
  --settings-links-item-add-color: #4c62ff;
  --settings-links-item-remove-color: #ff603e;
  --settings-links-item-bg: #fff;

  /* SETTINGS PRIVATE */
  --settings-private-item-bg: #fff;
  --settings-private-item-shadow: 0 4px 36px rgba(0, 0, 0, 0.08);
  --settings-private-item-hover: var(--dd-hover);

  /* SECURITY */
  --settings-security-item-shadow: 0 4px 36px rgba(0, 0, 0, 0.08);
  --settings-security-item-label: #9f9993;
  --settings-security-item-color: #252321;

  /* LANGUAGE */
  --language-color: #bbbbbe;
  --language-color-active: #eb9330;

  /* SKELETON */
  --skeleton-bg: #eee;
  --skeleton-gradient: linear-gradient(90deg, #eee, #f5f5f5, #eee);

  /* MOBILE NAV */
  --mobile-nav-color: #252321;
  --mobile-nav-icon: #aca09a !important;
  --mobie-nav-dd-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
  --mobile-nav-el-color: #77706a;

  /* FILTERS */
  --filters-gradient: linear-gradient(360deg, #f4f4f6 0%, #f7f7f8 100%);

  /* LIGHT BUTTON THEME */
  --light-button-bg: #e4e4e7;
  --light-button-color: #252321;
  --light-button-hover-bg: #d1d1d2;

  /* AVATARS BLOCK */
  --avatars-block-bg: #f9f9fa;
}
