@import 'src/styles/variables';

.sk-camouflage {
  &--grid {
    display: grid;
    grid-template-columns: auto;
    gap: 11px;

    @media (min-width: $screen-sm) {
      grid-template-columns: repeat(auto-fill, calc(50% - 20px));
    }

    @media screen and (min-width: $screen-md) {
      grid-template-columns: repeat(auto-fill, calc(33% - 20px));
    }

    @media screen and (min-width: $screen-lg) {
      grid-template-columns: repeat(auto-fill, calc(25% - 20px));
    }
  }

  &-team--grid {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    gap: 11px;

    @media (min-width: $screen-sm) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }

    @media (min-width: $screen-md) {
      grid-template-columns: 1fr;
    }

    @media (min-width: $screen-xxl) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--element {
    height: 200px;
  }

  &-team--element {
    height: 166px;
  }
}
