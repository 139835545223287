@import 'root_colors';

.dark-theme {
  /* Texts */
  --title-color: #f0f0f0;
  --text-color: #f0f0f0;
  --links-color: #4c62ff;
  --dark-text-color: #77706a;
  --block-title-color: #e9e9ec;
  --block-text-color: #afafb6;
  --block-nodata-color: #4c62ff;
  --block-more-color: #4c62ff;
  --card-title-color: #e9e9ec;
  --card-text-color: #afafb6;

  /* Page|Block|Card Background */
  --cartbg: #ffffff;
  --page-bg: #222225;
  --block-bg: #313131;
  --cards-bg: #2a2a2c;

  /* Chat */
  --chat-bg: var(--menu-bg);
  --toggle: #222225;
  --toggle-icon: #6f6d6c;
  --separatopr: #222225;
  --text-time: #797986;
  --active: linear-gradient(90deg, #29292d 0%, rgba(41, 41, 45, 0.15) 100%);
  --msg-input-bg: #ffffff;
  --msg-input-shadow: 0 4px 36px rgba(0, 0, 0, 0.08);
  --msg-input-text: #333333;
  --msg-input-place: #f0f0f0;
  --msg-button-bg: #252527;
  --msg-button-color: #f0f0f0;
  --msg-divider-day-text: #f0f0f0;
  --msg-divider-day-line: #252527;
  --msg-block-own: #d9dcf8;
  --msg-text-own: #252321;
  --msg-panel-bg: linear-gradient(85.13deg, #e26e43 -8.05%, #f8ce0e 162.8%);
  --msg-filter-btn-text: #5c5c66;
  --msg-filter-btn-text-active: #ffffff;
  --msg-filter-btn-bg: #29292d;
  --msg-filter-btn-bg-active: #222225;
  --msg-filter-btn-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
  --msg-button-delete: #ff0000;
  --msg-message-read: #3399ff;

  /* Icons */
  --cards-icon: #afafb6;

  --dd-hover: linear-gradient(90deg, #27272b 0%, rgba(39, 39, 43, 0.15) 100%);
  --search-border: 1px solid var(--dark-text-color);

  /* Buttons */

  --button-start: #e26e43;
  --button-end: #f8ce0e;
  --button-hover-start: #d26d3c;
  --button-hover-end: #dc9b23;
  --button-color: #18181b;

  /* input */
  --input-bg: #252527;
  --input-border: #252527;
  --input-caret: #fa9a2e;
  --input-focus: #fa9a2e;
  --input-text-color: #f0f0f0;

  /* header sidebar button */
  --button-header-menu: #eb9330;
  --main-bg-color: #252527;

  /* jodit */
  --h1: var(--title-color);
  --h2: var(--title-color);
  --container: var(--title-color);
  --ul-ol: var(--title-color);
  --a-color: #fa9a2e;
  --a-color-hover: #fefefe;

  /* AUTH */
  --auth-fileld-bg: #2a2a2c;
  --auth-fileld-border: #212122;
  --auth-a-hover: #f0f0f0;

  /* MENU */
  --menu-hover: linear-gradient(90deg, rgba(39, 39, 43, 0.15) 0%, #27272b 100%);
  --menu-bg: #2a2a2c;
  --menu-link: #afafb6;

  /* HEADER */
  --header-bg: #252527;
  --header-bottom-bg: #222225;
  --header-dummy: #222225;
  --header-link-active: #eb9330;
  --header-link: var(--menu-link);
  --header-menu-dropdown-bg: #242428;
  --header-menu-dropdown-shadow: 0 4px 20px rgba(0, 0, 0, 0.16);

  /* HEADER SEARCH */
  --search-bg: #313135;
  --search-shadow: 0px 4px 36px rgba(0, 0, 0, 0.2);
  --search-placeholder: #797986;
  --search-btn: #ffffff;

  /* PROFILE MENU */
  --profile-menu-image-bg: #222225;
  --profile-menu-avatar-bg: #222225;
  --profile-menu-dropdown-bg: #2a2a2c;
  --profile-menu-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
  --profile-menu-link: #eb9330;
  --profile-menu-link-active: #eb9330;
  --profile-menu-text-color: #e9e9ec;
  --profile-menu-gradient: linear-gradient(90deg, rgba(39, 39, 43, 0.15) 0%, #27272b 100%);

  /* FILTER RANGE */
  --range-track-color: #303035;
  --range-track-color-active: #59350d;
  --range-gradient: linear-gradient(85.13deg, #e26e43 -8.05%, #f8ce0e 162.8%);
  --range-shadow: 0px 2px 6px rgba(235, 147, 48, 0.2);
  --range-text: #b7b7be;
  --range-input-bg: #29292d;
  --range-input-color: #e9e9ec;

  /* FILTER SWITCH TYPE */
  --switch-type-border-color: #e2e2e4;
  --switch-type-selected: #fa9a2e;
  --switch-type-border: 2px solid #5c5c66;
  --switch-type-color: #afafb6;
  --switch-type-active-bg: linear-gradient(85.13deg, #e26e43 -8.05%, #f8ce0e 162.8%);
  --switch-type-active-shadow: 0px 2px 10px rgba(235, 147, 48, 0.2);
  --switch-type-active-color: #18181b;

  /* SELECT */
  --select-container-error: 1px solid red;
  --select-placeholder-color: #9f9993;
  --select-value-color: #f0f0f0;
  --select-bg: #252527;
  --select-option-bg: #eb9330;
  --select-option-hover-bg: #eeab6a;
  --select-option-color-active: #fff;

  /* SETTINGS NAV */
  --settings-nav-icon-color: #c0b4af;
  --settings-nav-link-color: #fff;

  /* SETTINGS LINKS */
  --settings-links-item-shadow: 0 4px 36px rgba(0, 0, 0, 0.08);
  --settings-links-item-link-color: #afafb6;
  --settings-links-item-add-color: #4c62ff;
  --settings-links-item-remove-color: #ff603e;
  --settings-links-item-bg: #2a2a2c;

  /* SETTINGS PRIVATE */
  --settings-private-item-bg: #2a2a2c;
  --settings-private-item-shadow: 0 4px 36px rgba(0, 0, 0, 0.08);
  --settings-private-item-hover: var(--dd-hover);

  /* SECURITY */
  --settings-security-item-shadow: 0 4px 36px rgba(0, 0, 0, 0.08);
  --settings-security-item-label: var(--text-color);
  --settings-security-item-color: var(--dark-text-color);

  /* LANGUAGE */
  --language-color: #bbbbbe;
  --language-color-active: #eb9330;

  /* SKELETON */
  --skeleton-bg: #eb9330;
  --skeleton-gradient: linear-gradient(90deg, #eeab6a, #eb9330, #eeab6a);

  /* MOBILE NAV */
  --mobile-nav-color: var(--title-color);
  --mobile-nav-icon: #aca09a !important;
  --mobie-nav-dd-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
  --mobile-nav-el-color: var(--dark-text-color);

  /* FILTERS */
  --filters-gradient: none;

  /* LIGHT BUTTON THEME */
  --light-button-bg: #e4e4e7;
  --light-button-color: #252321;
  --light-button-hover-bg: #d1d1d2;

  /* AVATARS BLOCK */
  --avatars-block-bg: var(--main-bg-color);

  /* etc */
  --grey-1: #202022;
  --matterhorn: #b2b2b2;
}
