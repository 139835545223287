.event-info-sk {
  &__status {
    max-width: 200px;
  }

  &__type {
    max-width: 100px;
    &:first-child {
      margin-bottom: 4px;
    }
  }

  &__counter {
    width: 34px;
    height: 34px;

    border-radius: 50%;
  }

  &__price {
    max-width: 250px;
  }

  &__place {
    width: 140px;
  }
}
