:root {
  --white: #ffffff;
  --grey-1: #ececef;
  --grey-2: #77706a;
  --sandstone: #77706a;
  --orange-1: #ff9d00;
  --orange-2: #eb9330;
  --orange-3: #ffbb00;
  --orange-4: #fa9a2e;
  --selective-yellow: #ffbb00;
  --tide: #c0b4af;
  --judge-grey: #5c5752;
  --granny-apple: #c1e1cd;
  --ocean-green: #5aaf79;
  --salle-green: #50a46e;
  --bokara-grey: #252321;
  --outrageous-orange: #ff603e;
  --lavender: #e4e4e7;
  --lavender2: #e9e9ec;
  --lavender3: #efeff0;
  --mercury: #dbd4d1;
  --ghost-white: #f4f4f6;
  --zorba: #9f9993;
  --medium-sea-green: #38bc67;
  --neon-blue: #4c62ff;
  --whisper: #e5e5e5;
  --black: #000000;
  --swirl: #cac7c4;
  --amazon: #408257;
  --white-smoke: #f0f0f0;
  --night-rider: #333333;
  --matterhorn: #575757;
  --snow: #fafafa;

  /* Toggle */
  --toggle-disabled: #d0d0d2;
  --toggle-active: #fa9a2e;
}
